<template>
  <div class="procureReportDaily">
    <h1>采购合同执行日报汇总</h1>
    <el-form :inline="true">
      <el-form-item label="采购日期">
        <el-date-picker :clearable="false" v-model="searchParam.date" type="date" style="width: 130px;" />
      </el-form-item>
      <el-form-item label="">
        <el-switch v-model="searchParam.hiddenZero" active-text="隐藏0剩余款" />
      </el-form-item>
      <el-form-item>
        <el-button-group>
          <el-button type="primary" @click="commitFindList()">搜索</el-button>
          <el-button @click="resetSearchParam()">重置</el-button>
        </el-button-group>
      </el-form-item>
    </el-form>
    <el-table :data="data.list" border show-summary :summary-method="summary" :span-method="handleSpanMethod" height="calc(100vh - 310px)">
      <el-table-column prop="supplier" label="供应商" show-overflow-tooltip />
      <el-table-column prop="payAmount" label="预付款" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.payAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="truckQuantity" label="车数" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ scope.row.truckQuantity }}
        </template>
      </el-table-column>
      <el-table-column prop="productAmount" label="货款" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="productQuantity" label="数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="productPrice" label="单价" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productPrice) }}
        </template>
      </el-table-column>
      <el-table-column prop="residueAmount" label="剩余款" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.residueAmount) }}
        </template>
      </el-table-column>
      <el-table-column prop="productUnInvoiceQuantity" label="取货未开票数量" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productUnInvoiceQuantity) }}
        </template>
      </el-table-column>
      <el-table-column prop="productUnInvoiceAmount" label="取货未开票金额" show-overflow-tooltip align="right">
        <template #default="scope">
          {{ math.formatNumber(scope.row.productUnInvoiceAmount) }}
        </template>
      </el-table-column>
    </el-table>
    <div style="height: 10px;" />
  </div>
</template>

<script setup>
import { reactive, ref } from 'vue'
import { ElMessage, ElMessageBox } from 'element-plus'
import api from '../../apis/api'
import util from '../../utils/util'
import math from '../../utils/math'

const data = reactive({
  list: [],
})

const show = reactive({
})

const searchParam = reactive({})
const resetSearchParam = () => {
  searchParam.date = new Date()
  searchParam.supplierList = []
  searchParam.hiddenZero = false
}
resetSearchParam()
api.get('/backend/dict/getByCode', { params: { code: 'supplier' } }).then(res => {
  searchParam.supplierList = res.dictList
})

const commitFindList = () => {
  if (!searchParam.date) {
    ElMessage.error('请选择日期')
    return
  }
  if (!searchParam.supplierList || searchParam.supplierList == []) {
    ElMessage.error('供应商加载中，请稍候')
    return
  }
  data.list = []
  if (searchParam.supplierList.length > 0) {
    commitItem(0)
  }
}

const commitItem = (index) => {
  const hiddenZero = searchParam.hiddenZero
  const params = {}
  params.supplier = searchParam.supplierList[index].value
  params.dateGe = util.parseTime(searchParam.date, '{y}-{m}-{d}')
  params.dateLe = util.parseTime(searchParam.date, '{y}-{m}-{d}')
  api.get('/backend/statistics/procureAccumulateReportBySupplier', { params: params }).then(res => {
    for (const i in res.list) {
      if (hiddenZero && !res.list[i].residueAmount) {
        continue
      }
      data.list.push(res.list[i])
    }
    if (index < searchParam.supplierList.length - 1) {
      index++
      commitItem(index)
    }
  })
}

const summary = () => {
  const list = data.list
  let residueAmount = 0
  let productUnInvoiceQuantity = 0
  let productUnInvoiceAmount = 0
  const suppliers = []
  list.forEach(item => {
    if (suppliers.indexOf(item.supplier) <= 0) {
      suppliers.push(item.supplier)
      residueAmount = math.plus(residueAmount, item.residueAmount)
      productUnInvoiceQuantity = math.plus(productUnInvoiceQuantity, item.productUnInvoiceQuantity)
      productUnInvoiceAmount = math.plus(productUnInvoiceAmount, item.productUnInvoiceAmount)
    }
  })
  return ['合计', '', '', '', '', '', math.formatNumber(residueAmount), math.formatNumber(productUnInvoiceQuantity), math.formatNumber(productUnInvoiceAmount)]
}

const handleSpanMethod = ({ row, columnIndex }) => {
  const columnIndexs = [2, 3, 4, 5] // 不合并
  if (columnIndexs.includes(columnIndex)) {
    return [1, 1]
  }
  if (row.col == 0) {
    return [0, 0]
  }
  return [row.col * 1, 1]
}
</script>

<style lang="less"></style>